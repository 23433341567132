import {
    REQUEST_F_DOCUMENT,
    RECEIVE_F_DOCUMENT,
    ERROR_F_DOCUMENT,
} from '../types'
import _cloneDeep from 'lodash/cloneDeep'
import {initialFetchState} from "../../constants/defaultValues"
import _get from "lodash/get"

export default (state = {}, action = {}) => {
    const newState = _cloneDeep(state)

    switch(action.type) {
        case REQUEST_F_DOCUMENT:
            newState[action.identifier] = {
                ...initialFetchState,
                data: _get(newState, [action.identifier, 'data'], {}),
                isFetching: true
            }
            return newState
        case RECEIVE_F_DOCUMENT:
            newState[action.identifier] = {
                ...newState[action.identifier],
                isFetching: false,
                data: action.payload,
                message: '',
                errors: {},
                validUntil: 3600000 + Date.now()
            }
            return newState
        case ERROR_F_DOCUMENT:
            newState[action.identifier] = {
                ...newState[action.identifier],
                isFetching: false,
                data: {},
                message: action.errors.server || '',
                errors: action.errors
            }
            return newState
        default:
            return state
    }
}