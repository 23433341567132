import {storage} from '../../constants/defaultValues'

import {
    UPDATE_CART,
    CLEAR_CART
} from '../types'
import _cloneDeep from "lodash/cloneDeep"
import _isEmpty from "lodash/isEmpty"

const cartLS = localStorage.getItem(storage.cart)
let INIT_CART = cartLS ? JSON.parse(cartLS) : {}

export default (state = INIT_CART, action) => {
    switch (action.type){
        case UPDATE_CART:
            const newState = _cloneDeep(state)
            if(_isEmpty(action.payload)){
                delete newState[action.id]
            }else{
                newState[action.id] = action.payload
            }

            localStorage.setItem(storage.cart, JSON.stringify(newState))

            return newState
        case CLEAR_CART:
            localStorage.setItem(storage.cart, JSON.stringify({}))
            return {}
        default:
            return state
    }
}