import { combineReducers } from 'redux'
import toaster from './toaster/reducer'
import auth from './auth/reducer'
import info from './info/reducer'
import strings from './strings/reducer'
import item from './item/reducer'
import sequence from './sequence/reducer'
import administrators from './administrators/reducer'
import roles from './roles/reducer'
import permissions from './permissions/reducer'
import adminStrings from './adminStrings/reducer'
import filters from './filters/reducer'
import {sidebar, locale, cookieSettings, orderModal} from './settings/reducer'
import references from './references/reducer'
import products from './products/reducer'
import documents from './documents/reducer'
import faqs from './faqs/reducer'
import blog from './blog/reducer'
import orders from './orders/reducer'
import f_documents from './f_documents/reducer'
import f_blog from './f_blog/reducer'
import f_blog_list from './f_blog_list/reducer'
import f_product_list from './f_product_list/reducer'
import f_products from './f_products/reducer'
import cart from './cart/reducer'

import enums from './enums/reducer'
import frontend from './frontend/reducer'

export default combineReducers({
  toaster,
  auth,
  info,
  strings,
  item,
  sequence,
  administrators,
  roles,
  permissions,
  enums,
  adminStrings,
  filters,
  sidebar,
  locale,
  cookieSettings,
  orderModal,
  products,
  references,
  documents,
  orders,
  faqs,
  blog,
  frontend,
  f_documents,
  f_blog,
  f_blog_list,
  f_product_list,
  f_products,
  cart,
})