/*
LocalStorage keys
*/
export const storage = {
    authentication: 'Authorization_' + btoa(window.location.host),
    sidebar: '__sidebar',
    cookie_settings: '__cs',
    cart: '__cart',
}
export const defaultSidebar = ''
export const localeOptions = ['cs-CZ']

//cookie disclaimer
export const defaultCookieSettings = {
    is_default: true,
    cookies: {
        necessary: true,
        analytics: false,
        marketing: false
    }
}

// Toaster
export const defaultToaster = {
    toasterType: "OK",
    title: '',
    message: '',
    counter: 0
}

//fetch state
export const initialFetchState = {
    data: {},
    isFetching: false,
    shouldReload: false,
    errors: {},
    message: ''
}

//fetch sequence state
export const initialSequenceFetchState = {
    data: [],
    isFetching: false,
    errors: {},
    message: ''
}

export const initialFrontendFetchState = {
    'landing': {
        data: [],
        isFetching: false
    },
    'documents': {},
    'blog': {},
    'blogList': {
        data: [],
        isFetching: false
    },
    'products': {},
    'productList': {
        data: [],
        isFetching: false
    },
    'cart': {}
}

//enum fetch state
export const initialEnumFetchState = {
    'filter-boolean': {
        data: [
            {value: 1, label: 'yes'},
            {value: 0, label: 'no'},
            {value: null, label: 'any'},
        ],
        isFetching: false,
        shouldReload: false
    },
    'order-status': {
        data: [
            {value: 0, label: 'new'},
            {value: 1, label: 'approved'},
            {value: 2, label: 'finished'},
            {value: 3, label: 'cancelled'},
        ],
        isFetching: false,
        shouldReload: false
    }
}

//enum fetch Item state
export const initialEnumItemFetchState = {
    data: [],
    isFetching: false,
    shouldReload: false
}

//user state
export const initialUserFetchState = {
    isAuthenticated: false,
    isFetching: false,
    user: {},
}

