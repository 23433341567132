import {
    REQUEST_F_PRODUCT_LIST,
    RECEIVE_F_PRODUCT_LIST,
    ERROR_F_PRODUCT_LIST
} from '../types'
import {initialFrontendFetchState} from '../../constants/defaultValues'


export default (state = initialFrontendFetchState.productList, action = {}) => {
    switch(action.type) {
        case REQUEST_F_PRODUCT_LIST:
            return {
                ...state,
                data: state.data || {},
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_F_PRODUCT_LIST:
            const data = action.payload
            if(action.concatenate){
                data.items = state.data.items.concat(data.items)
            }

            return {
                ...state,
                isFetching: false,
                data: data,
                errors: {},
                message: '',
            }
        case ERROR_F_PRODUCT_LIST:
            return {
                ...state,
                isFetching: false,
                errors: action.errors,
                message: action.errors.server || '',
            }
        default:
            return state
    }
}